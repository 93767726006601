// export { DOM } from "./DOM.js";
// export { domStart } from "./domStart.js";
// export { React } from "./domReact.js";

// export { setWindowResponsive } from "./domWindowResponsive.js";
// export { addBrowserClass } from "./domBrowserDetect.js";

// export { domCustomScrollbar } from "./domCustomScrollbar.js";
// export { domAbsoluteFix } from "./domAbsoluteFix.js";
// export { domAccordion } from "./domAccordion.js";
// export { domAjaxForm } from "./domAjaxForm.js";
// export { domAjaxPagination } from "./domAjaxPagination.js";
// export { domDropDown } from "./domDropDown.js";
// export { domFormValidate } from "./domFormValidate.js";
// export { domOpenModal } from "./domOpenModal.js";
// export { DomResponsiveMarkup } from "./DomResponsiveMarkup.js";
// export { domScrollDrag } from "./domScrollDrag.js";
// export { DomTabs } from "./DomTabs.js";
// export { DomFixedNav } from "./DomFixedNav.js";
// export { domSlideLink } from "./domSlideLink.js";
// export { domCarousel } from "./domCarousel.js";
import { domStart } from "./domStart.js";
domStart();